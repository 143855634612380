.supplies-cta-block {
    background-color: $color-spm-lightblue;
    border-radius: $panel-border-radius;
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 1rem;
    .title {
        color: $color-spm-darkblue;
        margin-bottom: 1rem;
    }
    .cta {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @include max-screen($screen__m) {
        grid-template-columns: none;
        .cta {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
