//
//  Colors
//  ---------------------------------------------

$color-spm-blue: #1a73e8;
$color-spm-blue-rgb: rgb(26, 115, 232);
$color-spm-lightblue: #f2f7fa;
$color-spm-darkblue: #082a70;
$color-spm-lightyellow: #FEF7E9;
$color-spm-orange: #fc7a1e;
$color-spm-green: #20bf55;
$color-ba-blue: #5bc0eb;
$color-spm-lightred: #faebeb;

$color-spm-text-blue-body: #485963;
$color-spm-text-blue-head: #082a70;

$color-spm-border-grayblue: #e0e3ea;

$color-spm-blue2: #1b96f1;
$color-spm-blue3: #13a8e4;
$color-spm-orange2: #e833f1;
$color-spm-orange3: #e32276;

//
//  Color nesting
//  ---------------------------------------------

$primary__color: $color-spm-blue !default;
$primary__color__dark: darken($primary__color, 35%) !default; // #000
$primary__color__darker: darken($primary__color, 13.5%) !default; // #111
$primary__color__lighter: lighten($primary__color, 29%) !default; // #7d7d7d
$primary__color__light: lighten($primary__color, 45%) !default; // #a6a6a6

$secondary__color: #e8e8e8 !default;
$secondary__color__light: lighten($secondary__color, 5%) !default;

$primary__color__text: $color-spm-text-blue-body !default;
$primary__color__text__darker: darken(
    $color-spm-text-blue-body,
    13.5%
) !default;
$primary__color__text__lighter: lighten(
    $color-spm-text-blue-body,
    30%
) !default;
$primary__color__text__header: $color-spm-text-blue-head !default;

$page__background-color: #fff !default;
$panel__background-color: darken($page__background-color, 6%) !default;
$panel__background-color__dark: #333 !default;
$panel__border-color: $color-spm-border-grayblue !default;
$panel__divider-color: $color-spm-border-grayblue;

$active__color: #ff5501 !default;
$error__color: #e02b27 !default;

$disabled__color: #aaa;

//
//  Colors for contrast calc in buttons (bootstrap)

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $primary__color__text__darker !default;
$yiq-text-light: #fff !default;

//
//  Header
//  ---------------------------------------------

$header__background-color: $color-spm-blue !default;
$header-icons-color: #fff !default;
$header-icons-color-hover: $color-spm-darkblue !default;

//
//  Footer
//  ---------------------------------------------

$footer__background-color: $color-spm-lightblue !default;

//
//  Component
//  ---------------------------------------------

$component__background-color__dark: $color-spm-darkblue !default;
