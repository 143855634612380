//
//  Navigation variables
//  _____________________________________________

$navigation__background: #fff !default;
$navigation__border: "" !default;

$navigation-level0-item__font-size: 16 !default;
$navigation-level0-item__font-weight: $font-weight__semibold !default;
$navigation-level0-item__line-height: false !default;
$navigation-level0-item__margin: 0 !default;
$navigation-level0-item__padding: 12px $indent__xl 12px 15px !default;
$navigation-level0-item__text-transform: none !default;

$navigation-level0-item__background: "" !default;
$navigation-level0-item__border: 1px solid darken($color-spm-lightblue, 20%) !default;
$navigation-level0-item__color: $color-spm-text-blue-body !default;
$navigation-level0-item__text-decoration: none !important;

$navigation-level0-item__active__background: "" !default;
$navigation-level0-item__active__border-color: $color-spm-blue !default;
$navigation-level0-item__active__border-style: solid !default;
$navigation-level0-item__active__border-width: 0 0 0 8px !default;
$navigation-level0-item__active__color: $color-spm-text-blue-body !default;
$navigation-level0-item__active__text-decoration: none !important;

$submenu__background: $color-gray05 !default;
$submenu__border: $color-gray09 !default;
$submenu__padding-top: $indent__s !default;
$submenu__padding-right: 0 !default;
$submenu__padding-bottom: $indent__s !default;
$submenu__padding-left: 15px !default;
$submenu__font-size: "" !default;
$submenu__font-weight: $font-weight__regular !default;
$submenu-item__line-height: 1.4 !default;

$submenu-item__background: "" !default;
$submenu-item__border: "" !default;
$submenu-item__color: $color-gray34 !default;
$submenu-item__text-decoration: "" !default;

$submenu-item__active__background: "" !default;
$submenu-item__active__border: 8px !default;
$submenu-item__active__border-color: $color-spm-orange !default;
$submenu-item__active__border-style: solid !default;
$submenu-item__active__border-width: 0 0 0 $submenu-item__active__border !default;
$submenu-item__active__color: "" !default;
$submenu-item__active__text-decoration: "" !default;

//
//  Desktop navigation
//  ---------------------------------------------

$navigation-desktop__background: $navigation__background !default;
$navigation-desktop__border: "" !default;
$navigation-desktop__font-size: 1.5rem !default;
$navigation-desktop__font-weight: $font-weight__semibold !default;

$navigation-desktop-level0-item__line-height: normal !default;
$navigation-desktop-level0-item__margin: 0 !default;
$navigation-desktop-level0-item__padding: 12px !default;

$navigation-desktop-level0-item__background: "" !default;
$navigation-desktop-level0-item__border: "" !default;
$navigation-desktop-level0-item__color: $color-spm-text-blue-body !default;
$navigation-desktop-level0-item__text-decoration: none !default;
$navigation-desktop-level0-item__text-transform: none !default;

$navigation-desktop-level0-item__hover__background: "" !default;
$navigation-desktop-level0-item__hover__border: "" !default;
$navigation-desktop-level0-item__hover__color: $primary__color !default;
$navigation-desktop-level0-item__hover__text-decoration: $navigation-desktop-level0-item__text-decoration !default;

$navigation-desktop-level0-item__active__background: "" !default;
$navigation-desktop-level0-item__active__border-color: $color-spm-orange !default;
$navigation-desktop-level0-item__active__border-style: solid !default;
$navigation-desktop-level0-item__active__border-width: 0 0 2px !default;
$navigation-desktop-level0-item__active__color: $color-spm-text-blue-body !default;
$navigation-desktop-level0-item__active__text-decoration: $navigation-desktop-level0-item__text-decoration !default;

$submenu-desktop__background: $page__background-color !default;
$submenu-desktop__border-width: 0 !default;
$submenu-desktop__border-style: solid !default;
$submenu-desktop__border-color: $border-color__base !default;
$submenu-desktop__box-shadow: 0 $indent__xs $indent__xs rgba(0, 0, 0, 0.19) !default;
$submenu-desktop__font-size: "" !default;
$submenu-desktop__font-weight: $font-weight__bold !default;
$submenu-desktop__min-width: 230px !default;
$submenu-desktop__padding: 15px 0 !default;

$submenu-desktop-arrow: true !default; // [true|false]
$submenu-desktop-arrow__size: 10px !default;
$submenu-desktop-arrow__left: 20px !default;

$submenu-desktop-item__padding: 8px $indent__base !default;
$submenu-desktop-item__background: "" !default;
$submenu-desktop-item__border: "" !default;
$submenu-desktop-item__color: $primary__color__text !default;
$submenu-desktop-item__text-decoration: "" !default;

$submenu-desktop-item__hover__background: $color-spm-blue !default;
$submenu-desktop-item__hover__border: "" !default;
$submenu-desktop-item__hover__color: $color-white !default;
$submenu-desktop-item__hover__text-decoration: $navigation-desktop-level0-item__text-decoration !default;

$submenu-desktop-item__active__background: "" !default;
$submenu-desktop-item__active__border-color: $color-spm-orange !default;
$submenu-desktop-item__active__border-style: solid !default;
$submenu-desktop-item__active__border-width: 0 0 0 3px !default;
$submenu-desktop-item__active__color: "" !default;
$submenu-desktop-item__active__text-decoration: "" !default;
