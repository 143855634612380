$header__background-color: $color-spm-blue  !default;
$header-icons-color: $color-white  !default;
// This isn't working; the variables aren't being used
$header-icons-color-hover: $color-spm-darkblue  !default;

$addto-color: $text__color__muted  !default;
$addto-hover-color: $primary__color  !default;

$minicart-icons-color: $header-icons-color  !default;
$minicart-icons-color-hover: $header-icons-color-hover  !default;

$account-icons-color: $header-icons-color  !default;
$account-icons-color-hover: $header-icons-color-hover  !default;

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        // padding-top: $indent__base * 0.75;
        // padding-bottom: $indent__base * 0.75;
        position: relative;
    }
}

.logo {
    margin: 8px auto;
    position: relative;
    z-index: 5;
    font-size: 0;
    @media (max-width: 768px) {
            padding-right: 0;
            padding-left: 52px;
        }

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.contact-button {
    padding-left: 0;
    margin: 0;

    >a {
        display: block;
        padding: 7px 17px 7px 17px;
        font-size: 0;
    }
}
.page-main {
    >.page-title-wrapper {
        .page-title+.action {
            margin-top: $indent__l;
        }
    }
}

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

.customer.dropdown {
    @include lib-dropdown($_toggle-selector: ".customer-toggle",
        $_options-selector: ".customer-dropdown",
        $_dropdown-toggle-icon-content: $icon-account,
        $_dropdown-toggle-active-icon-content: $icon-account,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_icon-font-position: before,
        $_icon-font-size: 35px,
        $_icon-font-line-height: 33px,
        $_icon-font-color: $account-icons-color,
        $_icon-font-color-hover: $account-icons-color-hover,
        $_icon-font-color-active: $account-icons-color );
    color: $color-white;
    margin: 0 0 0 $indent__s;

    .customer-toggle {
        >span {
            display: none;
        }
    }
}

$mobile-header-padding: 1rem;
$desktop-header-padding: 1.5rem;

.mobile-header-top-row {
    padding-top: $desktop-header-padding;
    padding-bottom: $desktop-header-padding;

    @media (max-width: 768px) {
        padding-top: $mobile-header-padding;
        padding-bottom: $mobile-header-padding;
    }
}

.mobile-header-bottom-row {
    padding-top: $desktop-header-padding;
    padding-bottom: $desktop-header-padding;

    @media (max-width: 768px) {
        background: rgba(0, 0, 0, 0.1);
        padding-top: $mobile-header-padding;
        padding-bottom: $mobile-header-padding;
    }
}

//
//  Business USPs
//  ---------------------------------------------

.usp-container {
    background: #fff;
    color: $color-spm-text-blue-body;
    padding: 1rem 2rem;
    z-index: -1;
    @include max-screen($screen__m) {
        padding: 8px 4px;
    }
}

#business-usps {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;

    >li {
        margin: 0;
        font-size: 1.4rem;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 1rem;
        .icon {
            display: inline-block;
            width: 2rem;
            height: 2rem; 
        }
        .text {
            display: inline-block;
            line-height: normal;
        }
        @media (max-width: 768px) {
            display: block;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .header {
        &.panel {
            display: none;

            >.header.links {
                float: right;
                font-size: 0;
                @include lib-list-inline();
                margin-left: auto;

                >li {
                    font-size: $font-size__base;
                    margin: 0 0 0 15px;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;

            //padding: ($indent__s + 6) $indent__base / 2;
            >.customer.dropdown {
                float: right;
            }
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            //border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            @extend .abs-add-clearfix-desktop;
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }
    }

    .logo {
        margin: 0;
        font-size: 0;

        img {
            max-height: inherit;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .header {
        &.content {
            >.customer.dropdown {
                display: none;
            }
        }
    }
}