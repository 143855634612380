.button,
a.button {
    @include button-size(
        $indent__s,
        $indent__s,
        $font-size__base,
        $font-weight__bold,
        normal,
        $btn-border-radius
    );
    &.fill {
        @include button-variant($color-spm-blue, $color-spm-blue, #fff, #fff);
    }
    &.ghost {
        @include button-ghost-variant(
            $color-spm-blue,
            lighten($color-spm-blue, 45%),
            $color-spm-blue
        );
        &.white {
            @include button-outline-variant($color-white, $color-white);
        }
    }
    &.outline {
        @include button-outline-variant(
            $color-spm-blue,
            $color-spm-blue,
            $color-white
        );
    }
    &.link {
        @include button-link-variant($color-spm-blue, transparent);
    }
    &.underline {
        @include button-underline-variant($color-spm-blue);
    }
    &.small {
        @include button-size(
            $indent__xs,
            $indent__xs,
            $font-size__s,
            $font-weight__bold,
            normal,
            3px
        );
    }
        &.medium {
            @include button-size(1rem,
                1.5rem,
                $font-size__base,
                $font-weight__bold,
                normal,
                $btn-border-radius );
        }
    &.min-width {
        min-width: $btn-min-width;
    }
    &.br-t-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

// .button__ghost,
// a.button__ghost {

//   @include button-size($indent__s, $indent__s, $font-size__base, normal, 100px);
// }
