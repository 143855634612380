html {
    scroll-behavior: smooth;
}

.background-white {
    background-color: #fff;
}

.background-light {
    background-color: $color-spm-lightblue;
}

.background-dark-blue {
    background-color: $color-spm-darkblue;
}

.background-light-alt {
    background-color: $color-gray91;
}

.background-dark {
    background-color: $color-gray09;
}

.section-border-tb {
    border-top: 5px solid$color-spm-lightblue;
    border-bottom: 5px solid$color-spm-lightblue;
}

.header-contact {
    a {
        color: #fff;
        font-weight: bold;
        &:hover {
            color: $color-spm-darkblue;
            text-decoration: none;
        }
    }
}

.breadcrumbs {
    width: 100%;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    z-index: 1;
}

$header-height: 400px;

.content-header-container {
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    .content-image-container {
        @include position-absolute-fill();
        > picture {
            display: block;
            height: auto;
            max-width: 100%;
            width: 100%;
            > img {
                width: 100%;
            }
        }
    }
    .content-overlay {
        @include position-absolute-fill();
        &.image {
            opacity: 0.65;
        }
    }
    .content-header-content-container {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
        // text-align: center;
    }
    .content-header-content {
        padding: $indent__base 0;
    }
}

.catalog-product-view {
    .content-header-content {
        padding: ($section-space__base - 4) 0;
    }
}

.catalog-category-view {
    .content-header-content {
        padding: $indent__base 0;
    }
}

.checkout-cart-index,
.checkout-index-index {
    .content-header-content {
        padding: $indent__base 0;
    }
}

.content-header-adornment {
    position: absolute;
    bottom: -76px;
    @include curved-edge(#fff);
}

.customer-logos {
    .logo {
        display: block;
        width: auto;
        height: 60px;
        min-width: 50px;
        max-width: 160px;
    }
}

footer {
    .link {
        color: inherit;
        display: block;
        padding: $indent__base * 0.5 $indent__base * 0.5;
        transition: $link-transition;
        line-height: normal;
        margin-bottom: $indent__s;
        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }
    .contact-links {
        text-transform: uppercase;
        font-weight: bold;
    }
}

.img-stack {
    position: relative;
    .img-container {
        position: absolute;
        overflow: hidden;
        font-size: 0;
        &.top {
            top: 0;
        }
        &.bottom {
            bottom: 0;
        }
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        &.full {
            width: 100%;
        }
        &.large {
            width: 90%;
        }
        &.small {
            width: 50%;
        }
    }
}

.img-container {
    &.rounded {
        border-radius: $panel-border-radius;
        overflow: hidden;
    }
    &.circle {
        border-radius: 999px;
        overflow: hidden;
    }
    &.border {
        border: $panel-border-radius solid #fff;
        border-radius: $panel-border-radius * 2;
        > img {
            border-radius: $panel-border-radius;
        }
    }
    &.shadow {
        box-shadow: $panel-shadow;
    }
    .icon {
        width: $indent__base * 4;
        height: $indent__base * 4;
        display: flex;
        align-items: center;
        justify-content: center;
        &.icon__m {
            width: $indent__base * 3;
            height: $indent__base * 3;
        }
        &.icon__s {
            width: $indent__base * 2;
            height: $indent__base * 2;
        }
        &.icon__xs {
            width: $indent__base * 1.2;
            height: $indent__base * 1.2;
        }
        &.icon__xxs {
            width: $indent__base * 0.9;
            height: $indent__base * 0.9;
        }
    }
}

.icon-header {
    display: flex;
    align-items: center;
    line-height: $indent__base * 1.2;
    .icon {
        width: $indent__base * 1.2;
        height: $indent__base * 1.2;
        margin-right: $indent__base * 0.3;
    }
    @include max-screen($screen__m) {
        display: block;
        .featured-brand {
            margin-bottom: $indent__s;
        }
    }
}

a.img-container {
    transition: $link-transition;
    .icon {
        opacity: 0.8;
    }
    &:hover {
        .icon {
            opacity: 1;
        }
    }
}

.link {
    &:hover {
        color: $color-spm-blue;
    }
    &.link__body {
        color: $text__color;
        &:hover {
            text-decoration: none;
        }
    }
}

.panel-link {
    transition: $link-transition;
    border-radius: $panel-border-radius;
    background: transparent;
    border: 1px solid transparent;
    &:hover {
        text-decoration: none;
        //background-color: rgba(27, 187, 217, 0.1);
    }
    &.panel-link__white {
        &:hover {
            background-color: rgba(255, 255, 255, 0.05);
            border-color: rgba(255, 255, 255, 0.2);
            box-shadow: $panel-shadow-hover;
            transform: $panel-shadow-hover-translate;
        }
    }
}

// Text expander
.expand-text,
.glide-expand-text {
    position: relative;
    overflow: hidden;
    height: 4.2em;
    transition: height 0.3s ease-out;
    &::after {
        content: " ";
        @include position-absolute-fill();
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 40%,
            white 100%
        );
        transition: opacity 0.2s ease;
        pointer-events: none;
    }
    p:last-child {
        margin-bottom: 0;
    }
    &.lines-3 {
        height: 4.2em;
    }
    &.lines-4 {
        height: 6em;
    }
    &.lines-6 {
        height: 8em;
    }
    &.lines-9 {
        height: 12em;
    }
    &.lines-12 {
        height: 16em;
    }
    &[aria-expanded="true"] {
        // overflow: visible;
        // height: auto;
        &::after {
            opacity: 0;
        }
    }
}
.expander,
.glide-expander,
.expand-btn {
    background: transparent;
    color: $link__color;
    display: inline-block;
    transition: all 0.2s ease;
    cursor: pointer;
    border: none;
    padding-left: 0;
    padding-right: 0;
    &::after {
        content: "▼";
        display: inline-block;
        margin-left: 0.5rem;
        transition: all 0.2s ease;
        font-size: 0.8em;
    }
    &:hover {
        color: $color-spm-blue;
        border: none;
        &::after {
            transform: translateY(3px);
        }
    }
    &:focus,
    &:active {
        color: $color-spm-blue;
        border: none;
    }
    &.open {
        &::after {
            transform: rotate(180deg) translateY(2px);
        }
        &:hover {
            &::after {
                transform: rotate(180deg) translateY(5px);
            }
        }
    }
}

.section-shadow {
    box-shadow: $panel-shadow;
}

// Lazyloading image blur effect

.ls-blur-up-img {
    filter: blur(10px);
    transition: filter 400ms;
    &.lazyloaded {
        filter: blur(0);
    }
}

.skew-wrapper {
    overflow: hidden;
}
.skew-clip {
    overflow: hidden;
    &.skew-right {
        transform: skew(-10deg) translateX(10%);
        .image-container,
        .img-container {
            transform: skew(10deg) translateX(-10%);
        }
    }
    &.skew-left {
        transform: skew(-10deg) translateX(-10%);
        .image-container,
        .img-container {
            transform: skew(10deg) translateX(10%);
        }
    }
}

.featured-brand {
    color: $color-spm-green;
    &::before {
        content: "";
    }
}

.brand-scroller-wrapper {
    position: relative;
    .top-gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 70%;
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
    }
    .bottom-gradient {
        position: absolute;
        top: 70%;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
        );
    }
}
.brand-scroller-container {
    .brand-logo {
        width: 80%;
        height: 100%;
        margin-bottom: 0;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .content-header-container {
        .breadcrumbs {
            margin-left: $indent__s;
            margin-left: $indent__s;
        }
    }

    .content-header-container {
        .content-image-container {
            position: absolute;
            > picture {
                display: block;
                height: auto;
                min-height: $header-height;
                max-width: unset;
                > img {
                    width: 100%;
                }
            }
        }
        .content-header-content {
            //padding: $section-space__base 0;
        }
    }

    .skew-clip {
        &.skew-right,
        &.skew-left {
            transform: none;
            .image-container,
            .img-container {
                transform: none;
            }
        }
    }
}
