//
//  Layout & Display
//  ---------------------------------------------

$sizes-map: (
    xs: $section-space__xs,
    s: $section-space__s,
    base: $section-space__base,
    l: $section-space__l,
    xl: $section-space__xl,
);
$attributes: padding, margin;
$directions: top, bottom, left, right;

// Width  Height

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

// Padding and Margin

.no-padding {
    padding: 0 !important;
}
.no-padding-bottom {
    padding-bottom: 0 !important;
}
.no-padding-top {
    padding-top: 0 !important;
}
.no-margin {
    margin: 0 !important;
}
.no-top-margin {
    margin-top: 0 !important;
}
.no-bottom-margin {
    margin-bottom: 0 !important;
}

@each $attribute in $attributes {
    @each $direction in $directions {
        .section-#{$attribute}__#{$direction} {
            @each $size, $value in $sizes-map {
                @if $size == base {
                    #{$attribute}-#{$direction}: #{$value};
                } @else {
                    &.section-size__#{$size} {
                        #{$attribute}-#{$direction}: #{$value};
                    }
                }
            }
        }
    }
}

@each $direction in $directions {
    .section-overlap__#{$direction} {
        @each $size, $value in $sizes-map {
            @if $size == base {
                margin-#{$direction}: -#{$value};
            } @else {
                &.section-size__#{$size} {
                    margin-#{$direction}: -#{$value};
                }
            }
        }
    }
}

// Display

.nodisplay,
.no-display {
    display: none;
}

.nodisplay__force {
    display: none !important;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

// Position

.relative {
    position: relative;
}

.position-absolute-fill {
    @include position-absolute-fill();
}

//
//  Typography
//  ---------------------------------------------

.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}

.fw-semibold {
    font-weight: $font-weight__regular;
}
.fw-semibold {
    font-weight: $font-weight__semibold;
}
.fw-bold {
    font-weight: $font-weight__bold;
}

.font-0 {
    font-size: 0;
}

//
//  Components
//  ---------------------------------------------

.no-list-style {
    list-style: none none;
}

//
// Colors
//  ---------------------------------------------

.color-white {
    color: #fff;
    &.and-hover {
        &:hover {
            color: #fff;
        }
    }
}

.color-brand {
    color: $primary__color;
}

.default-text-color {
    color: $text__color;
    &.and-hover {
        &:hover {
            color: $text__color;
        }
    }
}

//
// Assorted
//  ---------------------------------------------

.pointer {
    cursor: pointer;
}
