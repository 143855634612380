.collapsible,
[data-role="collapsible"] {
    [data-role="title"] {
        font-size: $font-size__base;
        font-weight: $font-weight__regular;
        border: 1px solid $panel__border-color;
        border-radius: $panel-border-radius-small;
        padding: $indent__base * 0.75;
        cursor: pointer;
        .title {
            font-weight: inherit;
        }
        .arrow {
            transform: rotate(0deg);
            transition: all 0.2s ease;
        }
        &:hover {
            .arrow {
                transform: translateY(4px);
            }
        }
        &[aria-expanded="true"] {
            .arrow {
                transform: translateY(0) rotate(180deg);
            }
            &:hover {
                .arrow {
                    transform: translateY(-4px) rotate(180deg);
                }
            }
        }
    }
    [data-role="content"] {
        max-height: 320px;
        overflow-y: auto;
        padding: $indent__s !important;
        margin-bottom: 0;
    }
}
