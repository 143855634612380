.category-list-img {
  height: 260px;
  width: 100%;
}

.category-list-logo-img {
  height: 160px;
  width: 100%;
}

.category-header-img {
  height: 120px;
  width: 100%;
}

.product-list-img {
  height: 260px;
  width: 100%;
}

.product-view-img {
  height: 526px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media(max-width: 767px) {
    height: 320px;
  }
}

.product-list-related-img {
  height: 70px;
  width: 70px;
}

.blog-list-img {
  height: 230px;
  width: 100%;
}

.home-hero-img {
  height: 480px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media(max-width: 767px) {
    height: 230px;
  }
}

.featured-brand-img {
  height: 120px;
  width: 240px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.split-panel-img {
  height: 480px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media(max-width: 767px) {
    height: 230px;
  }
}