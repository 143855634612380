// Category View
.subcategories {
    list-style: none;
    padding: 0;
}

.block.filter {
    .filter-options-item {
        border-bottom: 1px solid $panel__divider-color;

        [data-role="title"] {
            border: none;
            padding: $indent__s;
        }

        [data-role="content"] {
            //max-height: 320px;
            overflow-y: auto;
            padding: $indent__s $indent__base * 0.75 !important;
        }
    }

    .filter-current {
        .action.remove {
            margin-left: $indent__xs;
        }
    }
}


// Product List
.products.list {
    .product-item-details {
        padding-bottom: 0;

        .product-title {
            padding: 0 $indent__l;
        }

        .estimated-delivery {
            padding: 0 $indent__l;
            margin: $indent__xs 0;
            //color: $color-gray40;
        }

        .estimated-delivery-date {
            //font-weight: bold;
            color: $color-spm-darkblue;
        }
    }
}

.product-item-description {
    margin-bottom: 0;

    ul {
        list-style: none;
        text-align: left;
        padding: 0 0 0 $indent__s;

        li {
            margin: 0;
            padding: 0;

            &::before {
                content: "\2714";
                color: $primary__color;
                font-weight: bold;
                display: inline-block;
                width: 1.2em;
                margin-left: -$indent__s;
            }

            &:first-child {
                padding-top: 0;
            }
        }
    }
}


// Product View
.catalog-product-view {
    .price-wrapper {
        .price-currency {

            //color: $color-spm-orange;
            .price {}
        }
    }

    .pricing-options {
        border-left: 3px solid $color-spm-orange;
        padding-left: $indent__s;

        .price-container {
            .price {
                color: $color-spm-orange;
            }

            .price-including-tax,
            .price-excluding-tax {
                display: inline;
            }

            .price-including-tax {
                font-size: 1.3rem;

                &:before {
                    font-size: unset;
                }

                .price {
                    font-size: unset;
                }
            }
        }
    }

    .product.attribute.description {
        h2:first-child {
            margin-top: 0;
        }
    }

    .products-related {
        .products {
            list-style: none;
            padding: 0;
        }

        .product-item {
            margin-bottom: 0;

            .product-item-name {
                font-weight: $font-weight__regular;
            }

            .price-box {
                margin: 0;
                padding: 0;

                .price {
                    font-size: $font-size__base;
                    font-weight: $font-weight__regular;
                }

                .price-including-tax,
                .price-excluding-tax {
                    display: inline;

                    .price {
                        font-weight: $font-weight__regular;
                    }
                }
            }
        }
    }

    .spec-table {
        width: 100%;
        border: 1px solid $panel__divider-color;
        border-top: none;
        margin-bottom: $indent__xl;

        .tr {
            border: 1px solid $panel__divider-color;
            border-top: none;
        }

        .tc {
            padding: $indent__s;
            border-right: 1px solid $panel__divider-color;
            border-top: 1px solid $panel__divider-color;

            &:last-child {
                border-right: none;
            }
        }

        .spec-label {
            font-weight: bold;
        }

        .spec-value {
            //font-weight: bold;
        }
    }
}

.product-options-wrapper {
    .co-title {
        display: block;
        color: $color-spm-darkblue;
        font-size: $font-size__base;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .co-sku {
        color: $primary__color__text__lighter;
    }
}

.sticky-summary {
    position: sticky;
    position: -webkit-sticky;
    top: $indent__xl;
}

.product-usps {
    margin-bottom: 0;

    ul {
        list-style: none;
        margin-bottom: 0;
        padding: $indent__xs 0 0 0;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-wrap: wrap;

        li {
            margin: 0;
            padding: $indent__xs $indent__base 0 0;
            width: 50%;
            font-weight: bold;
            font-size: 0.95em;

            &::before {
                content: "\2714";
                color: $primary__color;
                font-weight: bold;
                display: inline-block;
                width: 1.2em;
            }
        }
    }
}

.product-promo-info {
    padding: $indent__base;
    background-color: $color-spm-lightblue;
    border: 1px solid darken($color-spm-lightblue, 15%);

    .title,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.discontinued-info {
    padding: $indent__base;
    background-color: $color-spm-lightred;
    border: 1px solid darken($color-spm-lightred, 15%);

    .title,
    h2,
    h3,
    h4,
    h5 {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.label-note {
    font-size: $font-size__s;
    margin-left: 0.2em;
}

.finance-link {
    padding: $indent__s;
    background-color: $color-spm-lightyellow;
}

.product-options-bottom {
    .estimated-delivery {
        padding: $indent__s;
        background-color: $color-spm-lightblue;
    }

    .estimated-delivery-date {
        //font-weight: bold;
        color: $color-spm-darkblue;
    }

    .price-box {
        .price-wrapper {
            .price-currency {
                font-size: $font-size__l;

                .price {
                    font-size: $font-size__l;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .product-usps {
        ul {
            li {
                width: 100%;
            }
        }
    }
}


// Gallery 

.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 2rem;
}

.video-link {
    width: 100%;
    padding: 0;
    border: none;
    line-height: 0;

    &:focus,
    &:active,
    &:hover {
        border: none;
        box-shadow: none;
        color: $color-spm-blue;
        outline: none;
    }

    &:hover {
        .video-overlay {
            opacity: 0.9;

            &::after {
                font-size: 6rem;
            }
        }
    }

    .video-img {
        position: relative;
        background: #000;
    }

    .video-thumb {
        width: 100%;
    }

    .video-overlay {
        @include position-absolute-fill();
        background: $color-spm-darkblue;
        opacity: 0.8;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $button-transition;

        &::after {
            content: "\25BA";
            color: #fff;
            font-weight: bold;
            display: inline-block;
            font-size: 5rem;
            transition: inherit;
        }
    }

    .video-title {
        line-height: normal;
        margin-top: 0.5rem;
        display: block;
        text-align: left;
        font-weight: $font-weight__regular;
    }
}