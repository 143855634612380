$panel-border-radius: 3px;
$panel-border-radius-small: 3px;
$panel-border-radius-large: 10px;
$panel-shadow: none;
$panel-shadow-hover: none;
$panel-shadow-hover-translate: none;
$panel-content-padding: 3rem;
$panel-content-grid-gap: $content-grid-gap;

$panel-background-color: #fff;
$panel-background-color-dark: #333;



