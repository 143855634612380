.n2-panel {
    @include panel(
        #fff,
        auto,
        auto,
        0,
        0,
        $panel-border-radius,
        1px,
        solid,
        $panel__border-color
    );
    margin-bottom: $indent__base * 1.5;
    &.lm {
        margin-bottom: $indent__base * 2;
    }
    &.shadow {
        box-shadow: $panel-shadow;
    }

    &.border {
        border: 1px solid $panel__border-color;
    }
    &.no-border {
        border: none;
    }
    &.no-radius {
        border-radius: 0;
    }
    &.no-background {
        background-color: unset;
    }
    &.dark {
        color: $color-white;
        background-color: $color-gray20;
        h2,
        h3,
        h4 {
            color: #fff;
        }
        .link {
            color: inherit;
        }
    }
    &.grid {
        display: grid;
        grid-template-rows: auto;
        gap: $panel-content-grid-gap;
        &.a-fr-a {
            grid-template-rows: auto 1fr auto;
            grid-template-columns: 1fr;
        }
        &.a-a-fr {
            grid-template-rows: auto auto 1fr;
            grid-template-columns: 1fr;
        }
        &.a-a-fr-a {
            grid-template-rows: auto auto 1fr auto;
            grid-template-columns: 1fr;
        }
        &.a-fr-a-a-a {
            grid-template-rows: auto 1fr auto auto auto;
            grid-template-columns: 1fr;
        }
        &.a-fr-a-a-a-a-a {
            grid-template-rows: auto 1fr auto auto auto auto auto;
            grid-template-columns: 1fr;
        }
    }
    &.center-panel {
        margin-left: auto;
        margin-right: auto;
    }
    &.center-all {
        justify-content: center;
        text-align: center;
    }
    &.pad {
        padding: 3rem;
    }
    &.allow-overflow {
        overflow: visible;
    }
    &.sticky {
        position: sticky;
        position: -webkit-sticky;
    }
    &.br-large {
        border-radius: $panel-border-radius-large;
    }
    .top-radius-container {
        border-radius: $panel-border-radius $panel-border-radius 0 0;
        overflow: hidden;
    }
    .image-container {
        position: relative;
        font-size: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &.contain {
            img {
                object-fit: contain;
            }
        }
    }
    .content {
        padding: $indent__base * 1.5;
    }
    .actions {
        &.product,
        &.category {
            width: 100%;
            padding: 0 $indent__base * 2 $indent__base;
            .button {
                display: inline-block;
            }
        }
    }
}

@include max-screen($screen__m) {
    .n2-panel {
        margin-bottom: $indent__base;
        .content {
            padding: $indent__base;
        }
    }
}
