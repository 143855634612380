@mixin panel(
    $background-color: transparent,
    $width: auto,
    $height: auto,
    $margin: 0,
    $padding: 0,
    $border-radius: $panel-border-radius,
    $border-width: 0,
    $border-style: solid,
    $border-color: none
) {
    background-color: $background-color;
    width: $width;
    height: $height;
    margin: $margin;
    padding: $padding;
    border-radius: $border-radius;
    border-width: $border-width;
    border-style: $border-style;
    border-color: $border-color;
    overflow: hidden;
}
