.input-wrapper.currency.gbp {
    &::before {
        content: "£";
        position: absolute;
        line-height: $font-size__base * 2 !important;
        padding-left: $indent__xs;
        font-size: $font-size__base;
    }
    input {
        padding-left: $font-size__base + $indent__s;
        //color: #333;
        font-size: $font-size__base;
        line-height: $font-size__base * 2 !important;
        border: none;
        height: $font-size__base * 2 !important;
    }
}

.leasing-calculator {
    .calc-header {
        padding: $indent__s;
        background-color: $color-spm-darkblue;
    }
    .t {
        width: 100%;
        border: 1px solid $panel__divider-color;
        margin-bottom: 0;
    }
    .tr {
        background-color: #fff;
        border: 1px solid $panel__divider-color;
        border-top: none;
    }
    .tr.th {
        //background-color: darken($color-spm-lightblue, 3%);
        font-weight: bold;
        &:last-child {
            border-top: 1px solid $panel__divider-color;
        }
        .tc {
            border-top: none;
        }
    }
    .tc {
        padding: $indent__s;
        border-right: 1px solid $panel__divider-color;
        border-top: 1px solid $panel__divider-color;
        &:last-child {
            border-right: none;
        }
    }
    .monthly-price {
        color: $color-spm-orange;
    }
}

.products.list {
    .finance {
        margin: $indent__xs 0;
        .monthly-price {
            color: $color-spm-darkblue;
        }
    }
}
