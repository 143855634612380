$h1__margin-bottom__desktop: $indent__xl  !default;

body {
    @include lib-css(background-color, $page__background-color);
}

.page-main {
    >.page-title-wrapper {
        .page-title+.action {
            margin-top: $indent__l;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        >.breadcrumbs,
        >.top-container,
        >.widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .page-main {
        >.page-title-wrapper {
            .page-title {
                display: inline-block;
            }

            .page-title+.action {
                float: right;
                margin-top: $indent__base;
            }
        }
    }
}