.grid-item-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: $grid-list-gap;
    padding: 0;
    list-style: none;
    @include min-screen($screen__xl) {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
    @include max-screen($screen__m) {
        gap: $grid-list-gap / 2;
    }
    &.item-size {
        &__sm {
            grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        }
        &__md {
            grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        }
        &__lg {
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        }
        &__xl {
            grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
            @include max-screen($screen__s) {
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            }
        }
        &__full {
            grid-template-columns: 1fr;
        }
    }
}
.grid-title-and-link {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: $grid-list-gap;
    @include max-screen($screen__s) {
        display: block;
    }
}
