//  Messages
$message-global-note__background: $color-yellow-light2  !default;
$message-global-note__border-color: $color-yellow-light3  !default;
$message-global-note__color: $text__color  !default;

$message-global-note-link__color: $link__color  !default;
$message-global-note-link__color-hover: $link__hover__color  !default;
$message-global-note-link__color-active: $link__active__color  !default;

$message-global-caution__background: $color-red9  !default;
$message-global-caution__border-color: none !default;
$message-global-caution__color: $color-white  !default;

$message-global-caution-link__color: $link__color  !default;
$message-global-caution-link__color-hover: $link__hover__color  !default;
$message-global-caution-link__color-active: $link__active__color  !default;

$icon-edit: "\e606" !default;

.action.skip {
    &:not(:focus) {
        @extend .abs-visually-hidden;
    }

    &:focus {
        @include lib-css(background, $color-gray94);
        @include lib-css(padding, $indent__s);
        box-sizing: border-box;
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}

.action-skip-wrapper {
    height: 0;
    position: relative;
}

.customer.dropdown {
    @include lib-dropdown($_toggle-selector: ".customer-toggle",
        $_options-selector: ".customer-dropdown",
        $_dropdown-toggle-icon-content: $icon-account,
        $_dropdown-toggle-active-icon-content: $icon-account,
        $_dropdown-list-item-padding: false,
        $_dropdown-list-item-hover: false,
        $_icon-font-position: before,
        $_icon-font-size: 35px,
        $_icon-font-line-height: 33px,
        $_icon-font-color: $account-icons-color,
        $_icon-font-color-hover: $account-icons-color-hover,
        $_icon-font-color-active: $account-icons-color );
    color: $color-white;
    margin: 0 0 0 $indent__s;

    .customer-toggle {
        >span {
            display: none;
        }
    }
}

.subnav-message-container {
    background: $color-spm-lightblue;
    color: $color-spm-darkblue;
    padding: 8px 20px;
    text-align: center;
    z-index: -1;

    p {
        margin: 0;
    }
}

.about-container {
    display: grid;
    grid-template-columns: 1fr 18%;
    gap: 3rem;
    align-items: flex-start;

    .img-container {
        .g-icon {
            width: 100%;
            padding: 0 3rem;
        }
    }

    @include max-screen($screen__m) {
        grid-template-columns: unset;
    }
}

//
//  Global notice
//  ---------------------------------------------

.message.global {
    p {
        margin: 0;
    }

    &.noscript,
    &.cookie {
        @include lib-message($_message-type: global-note);
        margin: 0;
    }

    &.cookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;

        .actions {
            margin-top: $indent__s;
        }
    }

    &.demo {
        @include lib-message($_message-type: global-caution);
        margin-bottom: 0;
        text-align: center;
    }
}


//
//  Widgets
//  ---------------------------------------------

.sidebar {

    .widget.block:not(:last-child),
    .widget:not(:last-child) {
        @extend .abs-margin-for-blocks-and-widgets;
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget.block {
        @include lib-css(margin, $indent__base 0);
    }
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

.field-recaptcha {
    margin-bottom: 2rem;

    .field {
        display: none;
    }
}

.our-customers {
    display: grid;
    grid-template-rows: auto;
    .title {
        margin-top: 0;
        @media (max-width: $screen__s) {
            text-align: center;
        }
    }
    .customer-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        padding-top: 2rem;
        .icon {
            margin: 0 auto;
        }
        @include max-screen($screen__xl) {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        }
    }
}