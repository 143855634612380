//  Footer
$footer__background-color: $color-spm-lightblue  !default;

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    margin-top: auto;
    position: relative;
    z-index: 2;
}

.footer-container {
    position: relative;

    .footer {
        color: $color-spm-text-blue-body;
        //text-align: center;
        line-height: 1.7em;

        // .copyright {
        //     @extend .abs-add-clearfix-desktop;
        // }
        .site-info {
            color: $color-spm-text-blue-body;
            opacity: 0.6;
            text-align: center;
            overflow: hidden;

            a {
                color: $color-spm-text-blue-body;
            }

            .net22 {
                margin-left: 2rem;
            }
        }
    }
}