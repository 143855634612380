@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/Campton/Campton-Book",
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/Campton/Campton-Medium",
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: "../fonts/Campton/Campton-Bold",
    $font-weight: 600,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1.page-title {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

.white-text {
    color: #fff;
}

// Deprecated
.default-text-color {
    color: $text__color;
    &.intense {
        color: $text__color__intense;
    }
    &.and-hover {
        &:hover {
            color: inherit;
        }
    }
}

.text-color__default {
    color: $text__color;
    &.intense {
        color: $text__color__intense;
    }
    &.and-hover {
        &:hover {
            color: inherit;
        }
    }
}

.text-color__emphasis {
    color: $text__color__emphasis;
}

.text-color__brand {
    color: $color-spm-blue;
}

.text-color__white {
    color: $text__color__white;
}

.color-brand {
    color: $color-spm-blue;
}

.link {
    &:hover {
        color: $color-spm-blue;
    }
    &.link__body {
        color: $text__color;
        &:hover {
            text-decoration: none;
        }
    }
}

.fw-bold {
    font-weight: bold;
}